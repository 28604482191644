import React from 'react';

import { useNavigation } from 'components/Navigation';
import { useScrollContext } from 'components/ScrollProvider';

import './AuthoringToolFeature.scss';

export const AuthoringToolFeature = ({ heading, text, background, call, useSvgBg }) => {
    const { anchors } = useNavigation();
    const { scrollTo } = useScrollContext();

    // const anchor = anchors.authoring;
    const anchor = anchors['platform-content'];

    function click(e) {
        e.preventDefault();

        if (anchor && anchor.anchorTop) {
            scrollTo(anchor.anchorTop);
        }
    }
    return (
        <section className={`authoring-tool-section ${useSvgBg ? 'use-svg-bg' : ''}`} style={{backgroundImage: `url('${background}')`}}>
            <div className="container">
                <h1>{heading}</h1>
                <h2>{text}</h2>
                <a
                    href="#authoring-tool"
                    onClick={click}
                >
                    <span className="scroll-down"></span>
                    <span className="label">{call}</span>
                </a>
            </div>
        </section>
    );
};

export default AuthoringToolFeature;
